<template>
  <sign-page
    title-text="我的审批"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :data-file-parm="{pDirPath: '/ask_leave/'}"
    :title-menus="[]"
    :tableActions="tableActions"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  askLeaveRequest as request
} from '@/api'

export default {
  computed: {
    workerId () {
      return (this.$store.getters.userPrevilege.find(v => v.objType === 'worker') || {objId: null}).objId
    },
    tableActions () {
      return [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'pass',
        label: '通过',
        show: (data) => {
          return data.status !== '审批通过'
        },
        dealFun: async (vm, data) => {
          await request.update(data.id, {
            status: '审批通过'
          })
          vm.loadData()
        }
      },
      {
        key: 'no',
        label: '驳回',
        show: (data) => {
          return data.status !== '审批驳回'
        },
        dealFun: async (vm, data) => {
          await request.update(data.id, {
            status: '审批驳回'
          })
          vm.loadData()
        }
      }]
    },
    columnList () {
      let data = [{
        title: '编号',
        sort: true,
        field: 'id'
      },
      {
        title: '员工姓名',
        sort: true,
        field: 'workerName'
      },
      {
        title: '员工工号',
        sort: true,
        field: 'workerCode'
      },
      {
        title: '请假类型',
        sort: true,
        field: 'type',
        filters: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {
          return {
            label: v,
            value: v
          }
        })
      },
      {
        title: '申请时间',
        sort: true,
        width: 130,
        field: 'pushTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.pushTime ? rowData.pushTime.slice(0, 16) : '-')
        }
      },
      {
        title: '开始时间',
        width: 130,
        sort: true,
        field: 'startTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 16) : '-')
        }
      },
      {
        title: '结束时间',
        sort: true,
        width: 130,
        field: 'endTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 16) : '-')
        }
      },
      {
        title: '请假时长',
        sort: true,
        field: 'timeLong'
      },
      {
        title: '请假事由',
        sort: true,
        field: 'reson'
      },
      {
        title: '状态',
        sort: true,
        field: 'status'
      }]
      return data
    }
  },
  data () {
    return {
      request
    }
  }
}
</script>
